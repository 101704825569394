<template>
  <v-form class="position-relative" :disabled="loading" v-if="submission">
    <v-row class="px-2" dense>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.orders.invoiceNumber')"
          :required="!readOnlyMode"
        >
          <validation-provider
            :rules="!readOnlyMode ? 'required' : ''"
            :name="$t('customer.orders.orderNumber')"
            v-slot="{ errors }"
          >
            <cz-input
              type="number"
              v-model="submission.documentNumber"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :lg="6" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.orders.status')"
          :required="!readOnlyMode"
          class="mb-4"
        >
          <div class="d-flex align-center" style="column-gap: 5px">
            <div class="text-body-1 font-weight-semibold">
              {{ orderSubmissionStatusText[submission.status] }}
            </div>
            <cz-menu-button
              :max-width="300"
              :menu-max-width="350"
              flat
              color="primary"
              :button-icon-src="mdiInformationOutline"
              :menuItems="submissionStatusMenuItems"
            />
          </div>
        </cz-form-field>
      </v-col>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field
          :label="$t('customer.orders.netAmount')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.netAmount')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.netAmount"
              type="number"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field
          :label="$t('customer.orders.vatAmount')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.vatAmount')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.vatAmount"
              type="number"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field label="סכום חשבונית כולל" :required="!readOnlyMode">
          <validation-provider
            rules="required"
            :name="'סכום חשבונית כולל'"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="submission.totalAmount"
              type="number"
              :error-messages="errors"
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field
          :label="$t('vendor.submissionCurrency')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required"
            :name="$t('customer.orders.currency')"
            v-slot="{ errors }"
          >
            <cz-currency-picker
              v-model="submission.currency"
              :items="currencyTypes"
              :error-messages="errors"
              outlined
              dense
              :readonly="readOnlyMode"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field
          :label="$t('customer.orders.issueDate')"
          :required="!readOnlyMode"
        >
          <validation-provider
            rules="required|date_format:yyyy-MM-dd"
            :name="$t('customer.orders.issueDate')"
            v-slot="{ errors }"
          >
            <cz-new-date-picker
              v-model="submission.issueDate"
              open-menu-on-click
              :readonly="readOnlyMode"
              dense
              :error-messages="errors"
            />
          </validation-provider>
        </cz-form-field>
      </v-col>
      <v-col :xl="4" :lg="6" :md="6" :sm="12">
        <cz-form-field :label="$t('customer.orders.orderNumber')">
          <div>
            <order-info-menu
              v-if="submission.relatedOrder"
              :order="submission.relatedOrder"
              @show-order="$emit('show-order')"
            />
          </div>
        </cz-form-field>
      </v-col>
      <v-col :lg="4" :md="6" :sm="12">
        <cz-form-field :label="$t('common.assignmentNumber')">
          <cz-input
            v-model="submission.assignmentNumber"
            type="number"
            dense
            :readonly="readOnlyMode"
          />
        </cz-form-field>
      </v-col>
      <v-col :cols="12" v-if="hasAttachedDocs">
        <div class="d-flex" style="column-gap: 20px">
          <div class="text-title mb-4 font-weight-semibold">
            {{
              $t('vendor.orderSubmissions.attachedDocuments', [
                additionalDocumentsCount
              ])
            }}
          </div>
          <cz-button
            text
            small
            :icon-src="showDocs ? mdiEye : mdiEyeOff"
            outlined
            icon-color="primary"
            :title="showDocs ? $t('common.hide') : $t('common.show')"
            :icon-size="22"
            @click="showDocs = !showDocs"
          />
        </div>
      </v-col>
      <template v-for="item in exisitingDocuments">
        <v-col :cols="6" :key="item._id">
          <v-card
            class="d-flex align-center"
            hover
            outlined
            @click="viewDocument(item)"
          >
            <v-card-text>
              <div class="d-flex" style="column-gap: 10px">
                <cz-icon
                  :size="44"
                  :src="mdiFileDocumentOutline"
                  color="primary"
                />
                <div>
                  <div
                    class="text-body-1 font-weight-semibold textPrimary--text"
                  >
                    {{ item.typeText }}
                  </div>
                  <div
                    class="textPrimary--text text-subtitle-2"
                    style="opacity: 0.5"
                  >
                    {{
                      $t('vendor.orderSubmissions.documentUploadedAt', [
                        formatDateTimeToDisplay(item.createdAt)
                      ])
                    }}
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col :cols="12" v-if="!readOnlyMode">
        <div class="text-title mb-4 font-weight-semibold">
          {{ $t('vendor.orderSubmissions.additionalDocuments') }}
        </div>
        <cz-button
          :title="$t('vendor.orderSubmissions.addAddtionalDocument')"
          :icon-src="mdiPlus"
          color="primary"
          text
          @click="onAddDocument"
        />
      </v-col>
      <template v-for="(item, index) in additionalDocumentsLocal">
        <v-col :lg="6" :md="6" :sm="12" :key="`add_doc_file_${index}`">
          <cz-form-field
            :label="$t('vendor.orderSubmissions.additionalDocumentFile')"
          >
            <cz-dropzone
              accept="application/pdf"
              @change="onAdditionalDocumentChange($event, index)"
              :height="55"
              :title="$t('dropzone.title')"
              :hide-title="!!item.file"
            >
              <div slot="files" class="py-2 dropzone-files" v-if="item.file">
                <cz-chip
                  v-if="item.file"
                  :text="item.file.name"
                  dark
                  color="secondary"
                  circle-edges
                  removeable
                  small
                  @remove="item.file = null"
                />
              </div>
            </cz-dropzone>
          </cz-form-field>
        </v-col>
        <v-col :lg="6" :md="6" :sm="12" :key="`add_doc_type_${index}`">
          <div class="d-flex align-center" style="column-gap: 10px">
            <cz-form-field
              class="flex-grow-1"
              :label="$t('vendor.orderSubmissions.additionalDocumentTypeLabel')"
            >
              <validation-provider
                :rules="item.file ? 'required' : ''"
                :name="
                  $t('vendor.orderSubmissions.additionalDocumentTypeLabel')
                "
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="item.type"
                  :items="addtionalDocumentTypes"
                  outlined
                  :error-messages="errors"
                  color="primary"
                  :placeholder="
                    $t(
                      'vendor.orderSubmissions.additionalDocumentTypePlaceholder'
                    )
                  "
                  :readonly="readOnlyMode"
                />
              </validation-provider>
            </cz-form-field>
            <cz-button
              :icon-src="mdiTrashCanOutline"
              icon-color="primary"
              text
              :icon-size="25"
              @click="onRemoveDocument(index)"
            />
          </div>
        </v-col>
      </template>
    </v-row>
  </v-form>
</template>

<script>
import {
  CzInput,
  CzFormField,
  CzButton,
  CzDropzone,
  CzChip,
  CzIcon,
  CzAutocomplete,
  CzMenuButton,
  CzCurrencyPicker,
  CzNewDatePicker
} from '@/components';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import OrderSubmissionDocumentType from '@/shared/types/OrderSubmissionDocumentType';
import {
  mdiFileDocumentOutline,
  mdiEye,
  mdiEyeOff,
  mdiPlus,
  mdiTrashCanOutline,
  mdiInformationOutline
} from '@mdi/js';
import currencyTypes from '@/shared/types/CurrencyTypes';
import OrderSubmissionStatus from '@/shared/types/OrderSubmissionStatus';
import { typesMixin } from '@/shared/mixins';
import OrderInfoMenu from '@/domain/customer/components/OrderInfoMenu.vue';
import { formatAmountWithCurrency } from '@/shared/services/currency.service';
import { formatDateTimeToDisplay } from '@/shared/services/dates.service';
export default {
  name: 'SubmissionForm',
  components: {
    CzInput,
    CzAutocomplete,
    CzFormField,
    CzDropzone,
    CzButton,
    CzCurrencyPicker,
    CzChip,
    CzNewDatePicker,
    CzMenuButton,
    OrderInfoMenu,
    CzIcon
  },
  mixins: [typesMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean
    },
    readOnlyMode: {
      type: Boolean
    },
    additionalDocuments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    addtionalDocumentTypes() {
      return [
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.performanceReport'
          ),
          value: OrderSubmissionDocumentType.PERFORMANCE_REPORT
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.subContactInvoiceCopy'
          ),
          value: OrderSubmissionDocumentType.SUBCONTACT_INV_COPY
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.reference'
          ),
          value: OrderSubmissionDocumentType.REFERENCE
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.bookKeepingClearance'
          ),
          value: OrderSubmissionDocumentType.BOOK_KEEP_CLEAR
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.taxDeductionAuthorization'
          ),
          value: OrderSubmissionDocumentType.TAX_DEDUCTION_AUTH
        },
        {
          text: this.$t(
            'vendor.orderSubmissions.additionalDocumentTypes.deliveryCertification'
          ),
          value: OrderSubmissionDocumentType.DELIVERY_CERT
        }
      ];
    },
    additionalDocumentsCount() {
      return this.submission?.additionalDocuments?.length || 0;
    },
    exisitingDocuments() {
      if (this.submission?.additionalDocuments?.length && this.showDocs) {
        return this.submission.additionalDocuments;
      } else {
        return [];
      }
    },
    hasAttachedDocs() {
      return this.submission?.additionalDocuments?.length > 0;
    },
    submissionStatusMenuItems() {
      switch (this.submission?.status) {
        case OrderSubmissionStatus.PENDING_APPROVAL:
          return [
            {
              title: this.$t('customer.vendorSubmissions.pendingApprovalTitle'),
              subtitle: this.$t(
                'customer.vendorSubmissions.pendingApprovalSubtitle'
              )
            }
          ];
        case OrderSubmissionStatus.RETURNED:
          return [
            {
              title: this.$t('customer.vendorSubmissions.returnType'),
              subtitle: this.submission?.returnTypeText
            },
            {
              title: this.$t('customer.vendorSubmissions.note'),
              subtitle:
                this.submission?.note ||
                this.$t('customer.vendorSubmissions.noNotes')
            }
          ];
        case OrderSubmissionStatus.REJECTED:
          return [
            {
              title: this.$t('customer.vendorSubmissions.rejectType'),
              subtitle: this.submission?.rejectTypeText
            },
            {
              title: this.$t('customer.vendorSubmissions.note'),
              subtitle:
                this.submission?.note ||
                this.$t('customer.vendorSubmissions.noNotes')
            }
          ];

        default:
          return null;
      }
    }
  },
  data() {
    return {
      mdiFileDocumentOutline,
      mdiEye,
      mdiEyeOff,
      mdiTrashCanOutline,
      mdiInformationOutline,
      mdiPlus,
      currencyTypes,
      showDocs: false,
      additionalDocumentsLocal: [],
      submission: null
    };
  },
  methods: {
    formatAmountWithCurrency,
    formatDateTimeToDisplay,
    onAddDocument() {
      // add empty submission document to list
      this.additionalDocumentsLocal.push({
        type: '',
        file: null
      });
    },
    onRemoveDocument(index) {
      this.additionalDocumentsLocal.splice(index, 1);
    },
    onAdditionalDocumentChange(files, index) {
      this.additionalDocumentsLocal[index].file = files[0];
    },
    viewDocument(item) {
      window.open(item.file.url, '_blank');
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(value, this.submission)) {
          this.submission = cloneDeep(this.value);
        }
      }
    },
    submission: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    },
    additionalDocuments: {
      immediate: true,
      deep: true,
      handler(value) {
        if (!isEqual(value, this.additionalDocumentsLocal)) {
          this.additionalDocumentsLocal = cloneDeep(value);
        }
      }
    },
    additionalDocumentsLocal: {
      deep: true,
      handler(value) {
        this.$emit('update:additionalDocuments', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
